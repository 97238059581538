import React, { useState } from "react"

const ContactForm = () => {
  return (
    <form
      name="contact-form"
      method="post"
      action="/success"
      data-netlify="true"
	  >
		<input type="text" name="name" />
    <input type="text" name="email" />
    <input type="text" name="phone" />
    <textarea type="text" name="message" />
      <input type="hidden" name="form-name" value="contact-form" />
      <button type="submit">SEND</button>
    </form>
  )
}

export default ContactForm